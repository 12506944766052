/* Variables */
@import "theme/variables";

/* Mixins and functions */
@import "theme/vendor/breakpoint/breakpoint";
@include breakpoint-set("to ems", true);
@import "theme/vendor/magnific-popup/magnific-popup"; // Magnific Popup
@import "theme/vendor/susy/susy";
@import "theme/mixins";

/* Core CSS */
@import "theme/reset";
@import "theme/base";
@import "theme/forms";
@import "theme/tables";
@import "theme/animations";

/* Components */
@import "theme/buttons";
@import "theme/notices";
@import "theme/masthead";
@import "theme/navigation";
@import "theme/footer";
@import "theme/search";
@import "theme/syntax";

/* Utility classes */
@import "theme/utilities";

/* Layout specific */
@import "theme/page";
@import "theme/archive";
@import "theme/sidebar";
@import "theme/print";
